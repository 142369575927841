import React from 'react';
import AnimationRevealPage from 'shared/ui-kit/helpers/AnimationRevealPage.js';
import tw from 'twin.macro';
import styled from 'styled-components'; //eslint-disable-line
import { css } from 'styled-components/macro'; //eslint-disable-line
import Header from 'shared/ui-kit/components/headers/light.js';
import Footer from 'shared/ui-kit/components/footers/FiveColumnWithInputForm.js';
import MainFeature1 from 'shared/ui-kit/components/features/TwoColWithButton.js';
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from 'shared/ui-kit/components/features/ThreeColSimple.js';
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from 'shared/ui-kit/components/cards/ProfileThreeColGrid.js';

import SupportIconImage from 'shared/ui-kit/images/support-icon.svg';
import ShieldIconImage from 'shared/ui-kit/images/shield-icon.svg';
import CustomerLoveIconImage from 'shared/ui-kit/images/simple-icon.svg';

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Treact</Subheading>}
        heading="We are a modern design agency."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to disrupt the design space."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: '24/7 Support',
            description: 'Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport'
          },
          {
            imageSrc: ShieldIconImage,
            title: 'Strong Teams',
            description: 'Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport'
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: 'Customer Satisfaction',
            description: 'Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport'
          }
        ]}
        linkText=""
      />
      <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} />
      <Footer />
    </AnimationRevealPage>
  );
};
